#headerPartRoomEdit {
  width: 100vw;
  height: 60px;
  text-align: center;
  display: flex;
  flex-direction: column;
}

#HeaderRoomEdit {
  width: 100vw;
  font-size: 50;
  height: 60px;
}

#tableRoomEdit {
  width: 90vw;
  margin: auto;
}

#roomEdit_button1 {
  margin: 2px;
  background-color: #fc9c04;
  width: 180px;
  font-size: larger;
  margin-left: 5vw;
}

#roomEdit_button1:hover {
  background-color: #fccc77;
}

.formHalfWidthRoomEdit {
  width: 50%;
}

.fullWidthFormRoomEdit {
  width: 100%;
}

#submitRoomEditContainer {
  display: flex;
  flex-direction: row;
  text-align: center;
}


#submitRoomEdit1 {
  width: 31%;
  color: white;
  font-size: larger;
  padding: 1%;
  margin-left: 1%;
  margin-right: 1%;
  background: #fc9c04;
}

#submitRoomEdit2 {
  width: 31%;
  color: white;
  font-size: larger;
  padding: 1%;
  margin-left: 1%;
  margin-right: 1%;
  background: #fc9c04;
}

#submitRoomEdit3 {
  width: 31%;
  color: white;
  font-size: larger;
  padding: 1%;
  margin-left: 1%;
  margin-right: 1%;
  background: #fc9c04;
}

#addFormRoomEdit {
  margin: 5vw;
  margin-top: 0;
  width: 90vw;
}

#addRoomEdit {
  margin: 1%;
  padding-top: 5%;
  padding-bottom: 5%;
  width: 100%;
  background-color: #262626;
}

#formRoomEdit {
  color: white;
}

@media (orientation: landscape) {
  #formRoomEdit {
    width: 90%;
    color: white;
    font-size: 1.3vw;
  }
}

@media (orientation: portrait) {
  #formRoomEdit {
    width: 90%;
    color: white;
    font-size: 2.5vw;
  }
}