#headerPartClassInfoInstPriv {
  width: 100vw;
  height: 60px;
  text-align: center;
  display: flex;
  flex-direction: column;
}

#HeaderClassInfoInstPriv {
  width: 100vw;
  font-size: 50;
  height: 60px;
}

#levelTable {
  width: 90vw;
  margin: auto;
}

.classInfoInstPriv_button_container {
  width: 90vw;
  margin-left: 5vw;
}

#classInfoInstPriv_button1 {
  margin: 2px;
  background-color: #fc9c04;
  width: 250px;
  font-size: large;
  margin-left: 3vw;
  float: right;
}

#classInfoInstPriv_button1:hover {
  background-color: #fccc77;
}

#classInfoInstPriv_button2 {
  margin: 2px;
  background-color: #fc9c04;
  width: 250px;
  font-size: large;
  margin-left: 3vw;
  float: right;
}

#classInfoInstPriv_button2:hover {
  background-color: #fccc77;
}