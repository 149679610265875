@media (orientation: landscape) {
  #class_attendance_info_admin_button_remove {
    background-color: #fc9c04;
    color: white;
    width: 90%;
    height: 90%;
    text-align: center;
    font-size: small;
    font-weight: bold;
    cursor: pointer;
    border-style: none;
    border-radius: 5%;
  }

  #class_attendance_info_admin_button_remove:hover {
    background-color: #fccc77;

  }
}

@media (orientation: portrait) {
  #class_attendance_info_admin_button_remove {
    background-color: #fc9c04;
    color: white;
    width: 90%;
    height: 90%;
    text-align: center;
    font-size: 2.1vw;
    font-weight: bold;
    cursor: pointer;
    border-style: none;
    border-radius: 5%;
  }

  #class_attendance_info_admin_button_remove:hover {
    display: block;
    background-color: #fccc77;
    position: relative;
  }

}