#headerPartLeave{
  width: 100vw;
  height: 60px;
  text-align: center;
  display: flex;
  flex-direction: column;
}

#HeaderLeave{
  width: 100vw;
  font-size: 50;
  height: 60px;
}

#tableLeave{
  width: 90vw;
  margin: auto;
}

#leave_button1{
  margin: 2px;
  background-color:#fc9c04;
  width: 180px;
  font-size: larger;
  margin-left: 5vw;
}

#leave_button1:hover{
  background-color:#fccc77;
}

.formHalfWidthLeave{
  width: 50%;
}
.fullWidthFormLeave{
  width: 100%;
}


#submitLeave{
  margin-top: 2%;
  margin-left: 80%;
  color: white;
  font-size: larger;
  padding: 1%;
  background: #fc9c04;
}

#addFormLeave{
  margin: 5vw;
  width: 90vw;
}

#addLeave{
  margin: 1%;
  padding-top: 5%;
  padding-bottom: 5%;
  width: 100%;
  background-color:#262626;
}

#formLeave{
  color: white;
}

@media (orientation: landscape){
  #formLeave{
    width: 90%;
    color: white;
    font-size:1.5vw;
  }
}

@media (orientation: portrait){
  #formLeave{
    width: 90%;
    color: white;
    font-size:2.5vw;
  }
}
