#RegisterationPO {
    margin: 0.5%;
    padding: 2%;
}

#RegisterationSP {
    margin: 0.5%;
    padding: 2%;
}

#signIn {
    margin: 0.5%;
    padding: 2%;
}

#regSign {
    padding: 2%;
    width: 80%;
    float: right;
}

#signInButton {
    background-color: #ff9c00;
}

#registerationButton {
    background-color: #ff9c00;
}

#signInButton:hover {
    background-color: #fccc77;
}

#registerationButton:hover {
    background-color: #fccc77;
}

#signinsubmit {
    color: white;
    background-color: #ff9c00;
}

#signinsubmit:hover {
    color: white;
    background-color: #fccc77;
}

#signupsubmit {
    color: white;
    background-color: #ff9c00;
}

#signupsubmit:hover {
    color: white;
    background-color: #fccc77;
}

#propertyOwnerLogo {
    float: right;
    margin: 10px;
}

#serviceProviderLogo {
    float: right;
    margin: 10px;
}