#headerPartInstructor {
  width: 100vw;
  height: 60px;
  text-align: center;
  display: flex;
  flex-direction: column;
}

#HeaderInstructor {
  width: 100vw;
  font-size: 50;
  height: 60px;
}

#tableInstructor {
  width: 90vw;
  margin: auto;
}

#instructor_button1 {
  margin: 2px;
  background-color: #fc9c04;
  width: 180px;
  font-size: larger;
  margin-left: 5vw;
}

#instructor_button1:hover {
  background-color: #fccc77;
}

.formOne3rdWidthInstructor {
  width: 33%;
}

.formHalfWidthInstructor {
  width: 50%;
}

.fullWidthFormInstructor {
  width: 100%;
}

#danceCatDropdown {
  width: 40%;
}

#danceDropdown {
  width: 40%;
}

#removeField {
  display: flex;
  flex-direction: column;
  width: 2%;
  justify-content: center;
  justify-self: center;
  text-align: center;

}


#submitInstructor {
  margin-top: 2%;
  margin-left: 80%;
  color: white;
  font-size: larger;
  padding: 1%;
  background: #fc9c04;
}

#addFormInstructor {
  margin: 5vw;
  width: 90vw;
}

#addInstructor {
  margin: 1%;
  padding-top: 5%;
  padding-bottom: 5%;
  width: 100%;
  background-color: #262626;
}

#formInstructor {
  color: white;
}

#add_more_button_new{
  background-color: #25967d;
}

@media (orientation: landscape) {
  #formInstructor {
    width: 90%;
    color: white;
    font-size: 1.3vw;
  }
}

@media (orientation: portrait) {
  #formInstructor {
    width: 90%;
    color: white;
    font-size: 2.5vw;
  }
}