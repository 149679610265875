#tableLeaveEdit{
  width: 90vw;
  margin: auto;
}
#headerPartLeaveEdit{
  width: 100vw;
  height: 60px;
  text-align: center;
  display: flex;
  flex-direction: column;
}

#HeaderLeaveEdit{
  width: 100vw;
  font-size: 50;
  height: 60px;
}

#tableLeaveEdit{
  width: 90vw;
  margin: auto;
}

#leaveEdit_button1{
  margin: 2px;
  background-color:#fc9c04;
  width: 180px;
  font-size: larger;
  margin-left: 5vw;
}

#leaveEdit_button1:hover{
  background-color:#fccc77;
}

.formHalfWidthLeaveEdit{
  width: 50%;
}
.fullWidthFormLeaveEdit{
  width: 100%;
}

#submitLeaveEditContainer{
    display: flex;
    flex-direction:row;
    text-align: center;
}


#submitLeaveEdit1{
    width: 31%;
  color: white;
  font-size: larger;
  padding: 1%;
  margin-left: 1%;
  margin-right: 1%;
  background: #fc9c04;
}

#submitLeaveEdit2{
  width: 31%;
  color: white;
  font-size: larger;
  padding: 1%;
  margin-left: 1%;
  margin-right: 1%;
  background: #fc9c04;
}

#submitLeaveEdit3{
  width: 31%;
  color: white;
  font-size: larger;
  padding: 1%;
  margin-left: 1%;
  margin-right: 1%;
  background: #fc9c04;
}

#addFormLeaveEdit{
  margin: 5vw;
  margin-top: 0;
  width: 90vw;
}

#addFormLeaveEdit1{
  margin: 5vw;
  margin-top: 0;
  width: 90vw;
}

#addLeaveEdit{
  margin: 1%;
  padding-top: 5%;
  padding-bottom: 5%;
  width: 100%;
  background-color:#262626;
}
#addLeaveEdit1{
  margin: 1%;
  padding-top: 5%;
  padding-bottom: 5%;
  width: 100%;
  background-color:#262626;
}

#formLeaveEdit{
  color: white;
}

@media (orientation: landscape){
  #formLeaveEdit{
    width: 90%;
    color: white;
    font-size:1.3vw;
  }
}

@media (orientation: portrait){
  #formLeaveEdit{
    width: 90%;
    color: white;
    font-size:2.5vw;
  }
}

#formLeaveEdit1{
  color: white;
}

@media (orientation: landscape){
  #formLeaveEdit1{
    width: 90%;
    color: white;
    font-size:1.3vw;
  }
}

@media (orientation: portrait){
  #formLeaveEdit1{
    width: 90%;
    color: white;
    font-size:2.5vw;
  }
}
/* --------------*/

#leave_button1Edit{
  margin: 2px;
  background-color:#fc9c04;
  width: 180px;
  font-size: larger;
  margin-left: 5vw;
}

#leave_button1Edit:hover{
  background-color:#fccc77;
}  

#submitLeaveEdit{
  margin-top: 2%;
  margin-left: 80%;
  color: white;
  font-size: larger;
  padding: 1%;
  background: #fc9c04;
}

#formLeave{
  color: white;
}

@media (orientation: landscape){
  #formLeave{
    width: 90%;
    color: white;
    font-size:1.3vw;
  }
}

@media (orientation: portrait){
  #formLeave{
    width: 90%;
    color: white;
    font-size:2.5vw;
  }
}

