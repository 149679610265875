#headerPartAttendance {
  width: 100vw;
  height: 60px;
  text-align: center;
  display: flex;
  flex-direction: column;
}

#HeaderAttendance {
  width: 100vw;
  font-size: 50;
  height: 60px;
}

#levelTable {
  width: 90vw;
  margin: auto;
}

.attendance_button_container {
  width: 90vw;
  margin-left: 5vw;
}

#attendance_button1 {
  margin: 2px;
  background-color: #fc9c04;
  width: 250px;
  font-size: large;
  margin-left: 3vw;
  float: right;
}

#attendance_button1:hover {
  background-color: #fccc77;
}

#attendance_button2 {
  margin: 2px;
  background-color: #fc9c04;
  width: 250px;
  font-size: large;
  margin-left: 3vw;
  float: right;
}

#attendance_button2:hover {
  background-color: #fccc77;
}

#class_description_attendance {
  width: 90vw;
  margin: auto;
}

#inst_share_id{
  width: 93vw;
  text-align: center;
  margin-left: 2vw;
}