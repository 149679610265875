#headerPartClassInfoStudPrivInstructor {
  width: 90vw;
  margin-left: 5vw;
  height: 30px;
  text-align: center;
}

#HeaderClassInfoStudPrivInstructor {
  height: 50px;
  width: 300px;
  font-size: 50;
  background-color: white;
  color: black;
  justify-content: center;
  text-align: center;
  padding: 5px;
}

#iconClassInfoStudPrivInstructor {
  display: inline;
  width: 50px;
  padding-right: 5px;
  justify-self: center;
}

#tableClassInfoStudPrivInstructor {
  width: 90vw;
  margin: auto;
}

#inst_add_button1 {
  margin: 2px;
  background-color: #fc9c04;
  width: 180px;
  font-size: larger;
  margin-left: 5vw;
  float: right;
}

#inst_add_button1:hover {
  background-color: #fccc77;
}