#headerPartClassInfoStudPriv {
  width: 100vw;
  height: 60px;
  text-align: center;
  display: flex;
  flex-direction: column;
}

#HeaderClassInfoStudPriv {
  width: 100vw;
  font-size: 50;
  height: 60px;
}

#levelTable {
  width: 90vw;
  margin: auto;
}

.classInfoStudPriv_button_container {
  width: 90vw;
  margin-left: 5vw;
}

#classInfoStudPriv_button1 {
  margin: 2px;
  background-color: #fc9c04;
  width: 250px;
  font-size: large;
  margin-left: 3vw;
  float: right;
}

#classInfoStudPriv_button1:hover {
  background-color: #fccc77;
}

#classInfoStudPriv_button2 {
  margin: 2px;
  background-color: #fc9c04;
  width: 250px;
  font-size: large;
  margin-left: 3vw;
  float: right;
}

#classInfoStudPriv_button2:hover {
  background-color: #fccc77;
}