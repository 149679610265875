#headerPartEmployeeEdit{
  width: 100vw;
  height: 60px;
  text-align: center;
  display: flex;
  flex-direction: column;
}

#HeaderEmployeeEdit{
  width: 100vw;
  font-size: 50;
  height: 60px;
}



#profilePictureChangeEmployeeEdit{
  width: 90vw;
  margin-left: 5vw;

}



#employeeEdit_button1{
  margin: 2px;
  background-color:#fc9c04;
  width: 180px;
  font-size: larger;
  margin-left: 5vw;
}

#employeeEdit_button1:hover{
  background-color:#fccc77;
}

.formHalfWidthEmployeeEdit{
  width: 50%;
}
.fullWidthFormEmployeeEdit{
  width: 100%;
}

#submitEmployeeEditContainer{
    display: flex;
    flex-direction:row;
    text-align: center;
}


#submitEmployeeEdit1{
  width: 31%;
  color: white;
  font-size: larger;
  padding: 1%;
  margin-left: 1%;
  margin-right: 1%;
  background: #fc9c04;
}

#submitEmployeeEdit1:hover{
  background-color:#fccc77;
}

#submitEmployeeEdit2{
  width: 31%;
  color: white;
  font-size: larger;
  padding: 1%;
  margin-left: 1%;
  margin-right: 1%;
  background: #fc9c04;
}

#submitEmployeeEdit2:hover{
  background-color:#fccc77;
}

#submitEmployeeEdit3{
  width: 31%;
  color: white;
  font-size: larger;
  padding: 1%;
  margin-left: 1%;
  margin-right: 1%;
  background: #fc9c04;
}

#submitEmployeeEdit3:hover{
  background-color:#fccc77;
}

#submitEmployeeEdit4{
  width: 100%;
  color: white;
  font-size: larger;
  padding: 1%;
  margin-top: 1%;
  background: #fc9c04;
}

#submitEmployeeEdit4:hover{
  background-color:#fccc77;
}

#addFormEmployeeEdit{
  margin: 5vw;
  margin-top: 0;
  width: 90vw;
}

#addEmployeeEdit{
  margin: 1%;
  padding-top: 5%;
  padding-bottom: 5%;
  width: 100%;
  background-color:#262626;
}



@media (orientation: landscape){
  #formEmployeeEdit{
    width: 90%;
    color: white;
    font-size:1.3vw;
  }
  #containerEmployeeEdit{
    display: flex;
    flex-direction: row;
    float: left;
    margin-left: 5vw;
  }
  
  #cardEmployeeEdit{
    width: 200px;
    display: flex;
    flex-direction: column;
    justify-content: top;
    margin-top: 2%;
  }
  
  #addFormEmployeeEdit{
    width: 75vw;
    margin-left: 5vw;
    margin-right: 5vw;
  }
}

@media (orientation: portrait){
  #formEmployeeEdit{
    width: 90vw;
    color: white;
    font-size:2.5vw;
  }
  #containerEmployeeEdit{
    display: flex;
    flex-direction: column;
  }
  
  #cardEmployeeEdit{
    width: 200px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  #addFormEmployeeEdit{
    width: 90vw;
    margin-left: 5vw;
  }
}

#employee_reset_password{
  margin: 2px;
  background-color:#fc9c04;
  width: 180px;
  font-size: larger;
  margin-left: 5vw;
}

#employee_reset_password:hover{
  background-color:#fccc77;
}


#resetPasswordEmployeeEdit{
  margin: 5vw;
  width: 90vw;
}

#resetEmployeeEdit{
  margin: 1%;
  padding-top: 5%;
  padding-bottom: 5%;
  width: 100%;
  background-color:#262626;
}

#formResetPasswordEmployee{
  color: white;
}

#submitResetPasswordEmployee{
  margin-top: 2%;
  margin-left: 80%;
  color: white;
  font-size: larger;
  padding: 1%;
  background: #fc9c04;
}