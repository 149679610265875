#headerPartEmployee{
  width: 100vw;
  height: 60px;
  text-align: center;
  display: flex;
  flex-direction: column;
}

#HeaderEmployee{
  width: 100vw;
  font-size: 50;
  height: 60px;
}

#tableEmployee{
  width: 90vw;
  margin: auto;
}

#employee_button1{
  margin: 2px;
  background-color:#fc9c04;
  width: 180px;
  font-size: larger;
  margin-left: 5vw;
}

#employee_button1:hover{
  background-color:#fccc77;
}

.formHalfWidthEmployee{
  width: 50%;
}
.fullWidthFormEmployee{
  width: 100%;
}


#submitEmployee{
  margin-top: 2%;
  margin-left: 80%;
  color: white;
  font-size: larger;
  padding: 1%;
  background: #fc9c04;
}

#addFormEmployee{
  margin: 5vw;
  width: 90vw;
}

#addEmployee{
  margin: 1%;
  padding-top: 5%;
  padding-bottom: 5%;
  width: 100%;
  background-color:#262626;
}

#formEmployee{
  color: white;
}

@media (orientation: landscape){
  #formEmployee{
    width: 90%;
    color: white;
    font-size:1.5vw;
  }
}

@media (orientation: portrait){
  #formEmployee{
    width: 90%;
    color: white;
    font-size:2.5vw;
  }
}
