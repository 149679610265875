#headerPartAdminEdit {
  width: 100vw;
  height: 60px;
  text-align: center;
  display: flex;
  flex-direction: column;
}

#HeaderAdminEdit {
  width: 100vw;
  font-size: 50;
  height: 60px;
}



#profilePictureChangeAdminEdit {
  width: 90vw;
  margin-left: 5vw;

}



#adminEdit_button1 {
  margin: 2px;
  background-color: #fc9c04;
  width: 180px;
  font-size: larger;
  margin-left: 5vw;
}

#adminEdit_button1:hover {
  background-color: #fccc77;
}

.formHalfWidthAdminEdit {
  width: 50%;
}

.fullWidthFormAdminEdit {
  width: 100%;
}

#submitAdminEditContainer {
  display: flex;
  flex-direction: row;
  text-align: center;
}


#submitAdminEdit1 {
  width: 31%;
  color: white;
  font-size: larger;
  padding: 1%;
  margin-left: 1%;
  margin-right: 1%;
  background: #fc9c04;
}

#submitAdminEdit1:hover {
  background-color: #fccc77;
}

#submitAdminEdit2 {
  width: 31%;
  color: white;
  font-size: larger;
  padding: 1%;
  margin-left: 1%;
  margin-right: 1%;
  background: #fc9c04;
}

#submitAdminEdit2:hover {
  background-color: #fccc77;
}

#submitAdminEdit3 {
  width: 31%;
  color: white;
  font-size: larger;
  padding: 1%;
  margin-left: 1%;
  margin-right: 1%;
  background: #fc9c04;
}

#submitAdminEdit3:hover {
  background-color: #fccc77;
}

#submitAdminEdit4 {
  width: 100%;
  color: white;
  font-size: larger;
  padding: 1%;
  margin-top: 1%;
  background: #fc9c04;
}

#submitAdminEdit4:hover {
  background-color: #fccc77;
}

#addFormAdminEdit {
  margin: 5vw;
  margin-top: 0;
  width: 90vw;
}

#addAdminEdit {
  margin: 1%;
  padding-top: 5%;
  padding-bottom: 5%;
  width: 100%;
  background-color: #262626;
}



@media (orientation: landscape) {
  #formAdminEdit {
    width: 90%;
    color: white;
    font-size: 1.3vw;
  }

  #containerAdminEdit {
    display: flex;
    flex-direction: row;
    float: left;
    margin-left: 5vw;
  }

  #cardAdminEdit {
    width: 200px;
    display: flex;
    flex-direction: column;
    justify-content: top;
    margin-top: 2%;
  }

  #addFormAdminEdit {
    width: 75vw;
    margin-left: 5vw;
    margin-right: 5vw;
  }
}

@media (orientation: portrait) {
  #formAdminEdit {
    width: 90vw;
    color: white;
    font-size: 2.5vw;
  }

  #containerAdminEdit {
    display: flex;
    flex-direction: column;
  }

  #cardAdminEdit {
    width: 200px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  #addFormAdminEdit {
    width: 90vw;
    margin-left: 5vw;
  }
}

#admin_reset_password {
  margin: 2px;
  background-color: #fc9c04;
  width: 180px;
  font-size: larger;
  margin-left: 5vw;
}

#admin_reset_password:hover {
  background-color: #fccc77;
}


#resetPasswordAdminEdit {
  margin: 5vw;
  width: 90vw;
}

#resetAdminEdit {
  margin: 1%;
  padding-top: 5%;
  padding-bottom: 5%;
  width: 100%;
  background-color: #262626;
}

#formResetPasswordAdmin {
  color: white;
}

#submitResetPasswordAdmin {
  margin-top: 2%;
  margin-left: 80%;
  color: white;
  font-size: larger;
  padding: 1%;
  background: #fc9c04;
}