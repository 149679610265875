#existingTable {
  width: 90vw;
  margin: auto;
}

#submitViewSalary {
  margin: 2px;
  background-color: #fc9c04;
  width: 180px;
  font-size: larger;
  margin-left: 5vw;
}

#submitViewSalary:hover {
  background-color: #fccc77;
}

#submitDeleteSalary {
  margin: 2px;
  background-color: #fc9c04;
  width: 180px;
  font-size: larger;
  margin-left: 5vw;
}

#submitDeleteSalary:hover {
  background-color: #fccc77;
}