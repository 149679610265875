#headerPartInstructorAP {
  width: 100vw;
  height: 60px;
  text-align: center;
  display: flex;
  flex-direction: column;
}

#HeaderInstructorAP {
  width: 100vw;
  font-size: 50;
  height: 60px;
}

#tableInstructorAP {
  width: 90vw;
  margin: auto;
}

#instructorAP_button1 {
  margin: 2px;
  background-color: #fc9c04;
  width: 180px;
  font-size: larger;
  margin-left: 5vw;
}

#instructorAP_button1:hover {
  background-color: #fccc77;
}

.formOne3rdWidthInstructorAP {
  width: 33%;
}

.formHalfWidthInstructorAP {
  width: 50%;
}

.fullWidthFormInstructorAP {
  width: 100%;
}

#danceCatDropdown {
  width: 40%;
}

#danceDropdown {
  width: 40%;
}

#removeField {
  display: flex;
  flex-direction: column;
  width: 2%;
  justify-content: center;
  justify-self: center;
  text-align: center;

}


#submitInstructorAP {
  margin-top: 2%;
  margin-left: 80%;
  color: white;
  font-size: larger;
  padding: 1%;
  background: #fc9c04;
}

#addFormInstructorAP {
  margin: 5vw;
  width: 90vw;
}

#addInstructorAP {
  margin: 1%;
  padding-top: 5%;
  padding-bottom: 5%;
  width: 100%;
  background-color: #262626;
}

#formInstructorAP {
  color: white;
}

@media (orientation: landscape) {
  #formInstructorAP {
    width: 90%;
    color: white;
    font-size: 1.3vw;
  }
}

@media (orientation: portrait) {
  #formInstructorAP {
    width: 90%;
    color: white;
    font-size: 2.5vw;
  }
}