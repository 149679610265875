// Fonts
$firesans-light: 'FiraSans-Light', sans-serif, Arial, Helvetica;
$firesans-medium: 'FiraSans-Medium', sans-serif, Arial, Helvetica;
$firesans-semibold: 'FiraSans-SemiBold', sans-serif, Arial, Helvetica;

// Colors
$MainColor:rgb(255, 156, 0);
$MainColorHover: #fccc77;
$SecondMenuColor: rgb(31, 26, 26);

$ButtonColor:#fc9c04;
$ButtonColorHover:#fccc77;
$WhiteText:rgb(255, 255, 255);
$BlackText:rgb(0, 0, 0);

$FormBackgroundColor:rgb(31, 26, 26);
$default-background-color1: #fff;
$default-background-color2: #edeef2;
$default-background-color3: #f5f5f5;
$default-background-color4: #d3d3d3;

$boxShadow:rgba(0,0,0,0.1);



$primary-color-blue: #01031a;
$customed-color-blue: #202024;
$secondary-color-blue: #0c4b77;
$third-color-blue: #4345a8;
$fourth-color-blue: #81a2c3;
$primary-color-gold: #eed999;
$secondary-color-gold: #f1e2b7;
$third-color-gold: #fff4db;
