#headerPartPayment {
    width: 100vw;
    height: 60px;
    text-align: center;
    display: flex;
    flex-direction: column;
}

#HeaderPayment {
    width: 100vw;
    font-size: 50;
    height: 60px;
}

#payment {
    width: 90vw;
    margin: auto;
}

#submitPaymentContainer {
    display: flex;
    flex-direction: row;
    text-align: center;
    width: 90vw;
    margin-left: 5vw;
}

@media (orientation: landscape) {
    #submit_reset {
        width: 15%;
        color: white;
        font-size: medium;
        padding: 1%;
        margin-left: 1%;
        margin-right: 1%;
        background: #fc9c04;
    }

    #submit_view_summary {
        width: 15%;
        color: white;
        font-size: medium;
        padding: 1%;
        margin-left: 1%;
        margin-right: 1%;
        background: #fc9c04;
    }

    #submit_view_kitchen_payments {
        width: 15%;
        color: white;
        font-size: medium;
        padding: 1%;
        margin-left: 1%;
        margin-right: 1%;
        background: #fc9c04;
    }

    #submit_view_misc_payments {
        width: 15%;
        color: white;
        font-size: medium;
        padding: 1%;
        margin-left: 1%;
        margin-right: 1%;
        background: #fc9c04;
    }

    #submit_view_rent_payments {
        width: 15%;
        color: white;
        font-size: medium;
        padding: 1%;
        margin-left: 1%;
        margin-right: 1%;
        background: #fc9c04;
    }

    #submit_view_subs_payments {
        width: 15%;
        color: white;
        font-size: medium;
        padding: 1%;
        margin-left: 1%;
        margin-right: 1%;
        background: #fc9c04;
    }

    #submit_reset:hover {
        background-color: #fccc77;
    }

    #submit_view_summary:hover {
        background-color: #fccc77;
    }

    #submit_view_misc_payments:hover {
        background-color: #fccc77;
    }

    #submit_view_kitchen_payments:hover {
        background-color: #fccc77;
    }

    #submit_view_subs_payments:hover {
        background-color: #fccc77;
    }

    #submit_view_rent_payments:hover {
        background-color: #fccc77;
    }
}

@media (orientation: portrait) {
    #submit_reset {
        width: 30%;
        color: white;
        font-size: x-small;
        padding: 1%;
        margin-left: 1%;
        margin-right: 1%;
        background: #fc9c04;
    }

    #submit_view_summary {
        width: 30%;
        color: white;
        font-size: x-small;
        padding: 1%;
        margin-left: 1%;
        margin-right: 1%;
        background: #fc9c04;
    }

    #submit_view_kitchen_payments {
        width: 30%;
        color: white;
        font-size: x-small;
        padding: 1%;
        margin-left: 1%;
        margin-right: 1%;
        background: #fc9c04;
    }

    #submit_view_misc_payments {
        width: 30%;
        color: white;
        font-size: x-small;
        padding: 1%;
        margin-left: 1%;
        margin-right: 1%;
        background: #fc9c04;
    }

    #submit_view_rent_payments {
        width: 30%;
        color: white;
        font-size: x-small;
        padding: 1%;
        margin-left: 1%;
        margin-right: 1%;
        background: #fc9c04;
    }

    #submit_view_subs_payments {
        width: 30%;
        color: white;
        font-size: x-small;
        padding: 1%;
        margin-left: 1%;
        margin-right: 1%;
        background: #fc9c04;
    }

    #submit_reset:hover {
        background-color: #fccc77;
    }

    #submit_view_summary:hover {
        background-color: #fccc77;
    }

    #submit_view_misc_payments:hover {
        background-color: #fccc77;
    }

    #submit_view_kitchen_payments:hover {
        background-color: #fccc77;
    }

    #submit_view_subs_payments:hover {
        background-color: #fccc77;
    }

    #submit_view_rent_payments:hover {
        background-color: #fccc77;
    }
}