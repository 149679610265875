/* Variables and Imports */
@import '../variables';

/* Base Table Styling */
.table-tb-desktop {
  font-family: $firesans-light;
  border-collapse: collapse;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

/* Styling for Table Headers and Cells */
.table-tb-desktop td,
.table-tb-desktop th {
  border-bottom: 2px solid $default-background-color2;
  padding: 8px;
  text-align: center;
}

.table-tb-desktop th {
  border-bottom: 5px solid $default-background-color2;
  padding-top: 12px;
  padding-bottom: 12px;
  background-color: $ButtonColor;
  color: $WhiteText;
}

/* Styling for Table Rows */
.table-tb-desktop tr {
  background-color: $default-background-color1;
}

.table-tb-desktop tr:nth-child(even) {
  background-color: $default-background-color3;
}

.table-tb-desktop tr:nth-child(odd) {
  background-color: $default-background-color4;
}

/* Styling for Cells specifically */
.table-tb-desktop td {
  padding: 15px;
}

/* Styling for Navigation and Flexbox Alignment */
.prevnext-tb-desktop {
  padding-top: 8px;
  float: right !important;
  display: flex;
  align-items: center;
}

/* Centering Header Cell Content */
.header-cell-tb-desktop {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Filter Container Positioning */
.filter-container-table-tb-desktop {
  margin-left: 10px;
}

#scroll {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  margin-left: auto;
  margin-right: auto;
  display: block;
  overflow-x: auto;
  white-space: nowrap;
  text-align: center;
}

.filter-container-table-tb-desktop .ui.dropdown .menu {
  right: 0;
  left: auto;
}

@media (orientation: portrait) {
  .filter-container-table-tb-desktop {
    margin-left: 10px;
    width: auto !important;
  }
  .filter-container-table-tb-desktop .ui.dropdown .menu {
    right: 0;
    left: auto;
    width: auto !important;
  }
  .filter-container-table-tb-desktop .item {
    width: 130px;
  }
}
