#headerPartDance {
  width: 100vw;
  height: 60px;
  text-align: center;
  display: flex;
  flex-direction: column;
}

#HeaderDance {
  width: 100vw;
  font-size: 50;
  height: 60px;
}

#tableDance {
  width: 90vw;
  margin: auto;
}

#chooseCat {
  width: 90vw;
  margin: auto;
}

#dance_button1 {
  margin: 2px;
  background-color: #fc9c04;
  width: 180px;
  font-size: larger;
  margin-left: 5vw;
}

#dance_button1:hover {
  background-color: #fccc77;
}

.formHalfWidthDance {
  width: 50%;
}

.fullWidthFormDance {
  width: 100%;
}


#submitDance {
  margin-top: 2%;
  margin-left: 80%;
  color: white;
  font-size: larger;
  padding: 1%;
  background: #fc9c04;
}

#addFormDance {
  margin: 5vw;
  width: 90vw;
}

#addDance {
  margin: 1%;
  padding-top: 5%;
  padding-bottom: 5%;
  width: 100%;
  background-color: #262626;
}

#formDance {
  color: white;
}

#addFormDanceNewInst {
  width: 100%;
  display: flex;
  flex-direction: column;
}

#addDanceNewInst {
  width: 100%;
  background-color: #383838;
  border-style: double;
  border-color: white;
}

#formDanceNewInst {
  color: black;
}

@media (orientation: landscape) {
  #formDance {
    width: 90%;
    color: white;
    font-size: 1.3vw;
  }
}

@media (orientation: portrait) {
  #formDance {
    width: 90%;
    color: white;
    font-size: 2.5vw;
  }
}


#existingVsNewInstContainer {
  display: flex;
  flex-direction: row;
  text-align: center;
}


#existingInstButton {
  width: 50%;
  color: white;
  font-size: large;
  padding: 1%;
  margin-left: 1%;
  margin-right: 1%;
  background-color: #fc9c04;
}

#existingInstButton:hover {
  background-color: #fccc77;
}

#newInstButton {
  width: 50%;
  color: white;
  font-size: large;
  padding: 1%;
  margin-left: 1%;
  margin-right: 1%;
  background-color: #fc9c04;
}

#newInstButton:hover {
  background-color: #fccc77;
}

@media (orientation: portrait) {
  #existingInstButton {
    width: 50%;
    color: white;
    font-size: medium;
    padding: 1%;
    margin-left: 1%;
    margin-right: 1%;
    background-color: #fc9c04;
  }

  #existingInstButton:hover {
    background-color: #fccc77;
  }

  #newInstButton {
    width: 50%;
    color: white;
    font-size: medium;
    padding: 1%;
    margin-left: 1%;
    margin-right: 1%;
    background-color: #fc9c04;
  }

  #newInstButton:hover {
    background-color: #fccc77;
  }

  #passwordReEnterPort {
    font-size: x-small;
  }

  #holdAlonePort {
    font-size: x-small;
  }

  #salary_partner {
    font-size: x-small;
  }
}