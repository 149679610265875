@import '../variables';

#basicTableContainer {
  #basicTable {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
  }

  #basicTableHeader, #basicTableCell {
    padding: 8px;
    text-align: center;
    border-bottom: 2px solid $default-background-color2;
  }

  /* Responsive adjustments for smaller screens */
  @media (max-width: 768px) {
    #basicTable, #basicTableHeader, #basicTableCell, tbody, tr {
      display: block;
    }

    #basicTableHead tr {
      display: none;
    }

    #basicTableBody tr {
      border-bottom: none;
      margin-bottom: 20px;
      box-shadow: 0 2px 5px $boxShadow;
    }

    #basicTableCell {
      display: grid;
      grid-template-columns: auto 1fr;
      margin-bottom: 10px;
      &:before {
        content: attr(data-label);
        font-weight: bold;
        padding-right: 10px;
        text-align: left;
      }
    }

    #basicTableCell:last-child {
      margin-bottom: 0;
    }
  }
}
