#headerPartInstructorAdd {
  width: 100vw;
  height: 60px;
  text-align: center;
  display: flex;
  flex-direction: column;
}

#HeaderInstructorAdd {
  width: 100vw;
  font-size: 50;
  height: 60px;
}

#tableInstructorAdd {
  width: 90vw;
  margin: auto;
}

#instructor_add_button1 {
  margin: 2px;
  background-color: #fc9c04;
  width: 180px;
  font-size: larger;
  margin-left: 5vw;
  float: right;
}

#instructor_add_button1:hover {
  background-color: #fccc77;
}