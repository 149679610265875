/* Reset Styles */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* Global Styles */
body {
    font-family: Arial, sans-serif;
    font-size: 16px;
    line-height: 1.5;
    color: #333 !important;
}

a {
    text-decoration: none;
    color: #333 !important;
}

ul {
    list-style: none;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 30px;
}

/* Header Styles */
header {
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0,0,0,.1) !important;
}

nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
}

.logo img {
    height: 40px;
}

nav ul {
    display: flex;
}

nav li {
    margin-left: 30px;
}

nav a {
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 1px;
    transition: all .3s ease;
}

nav a:hover {
    color: #E67E22 !important;
}

/* Hero Section Styles */
.hero {
    background-size: cover;
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.hero-text {
    text-align: center;
    color: #E67E22 !important;
}

.hero h1 {
    font-size: 50px;
    margin-bottom: 20px;
    text-shadow: 1px 1px 2px rgba(0,0,0,.5) !important;
}

.hero p {
    font-size: 24px;
    margin-bottom: 30px;
    text-shadow: 1px 1px 2px rgba(0,0,0,.5) !important;
}

.btn {
    display: inline-block;
    padding: 12px 30px;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 1px;
    border-radius: 30px;
    transition: all .3s ease;
    cursor: pointer;
}

.btn-primary {
    background-color: #E67E22 !important;
    color: #fff;
    margin-right: 20px;
}

.btn-primary:hover {
    background-color: #D35400 !important;
}

.btn-secondary {
    background-color: #fff !important;
    color: #333 !important;
    border: 2px solid #E67E22 !important;
}

.btn-secondary:hover {
    background-color: #E67E22 !important;
    color: #fff;
}

/* Featured Classes Section Styles */
.featured-classes {
    padding: 80px 0;
    background-color: rgba(196, 111, 37, 0.192) !important;
}

.featured-classes h2 {
    text-align: center;
    margin-bottom: 40px;
}

.class-grid {
    display: flex;
    justify-content: center;
    align-items: stretch;
}

.class {
    margin: 0 20px;
    text-align: center;
}

.class img {
    width: 100%;
    height: 300px;
    object-fit: cover;
    border-radius: 10px;
    margin-bottom: 20px;
}

.class h3 {
    font-size: 24px;
    margin-bottom: 10px;
}

.class p {
    font-size: 16px;
    margin-bottom: 20px;
}

/* About Us Section Styles */
.about-us {
    padding: 80px 0;
}

.testimonials {
    display: flex;
    justify-content: center;
    align-items: stretch;
    margin-top: 50px;
}

.testimonial {
    padding: 30px;
    background-color: #fff !important;
    box-shadow: 0 2px 4px rgba(0,0,0,.1) !important;
    text-align: center;
    margin-right: 20px;
    border-radius: 10px;
    width: 450px;
}

.testimonial img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 50%;
    margin-bottom: 20px;
}

.testimonial p {
    font-size: 18px;
    margin-bottom: 20px;
}

.testimonial span {
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 1px;
}

/* Classes Section Styles */
.classes {
    padding: 80px 0;
}

.class-grid {
    display: flex;
    justify-content: center;
}