#newTable {
  width: 90vw;
  margin: auto;
}

#submitNewSalary {
  margin: 2px;
  background-color: #fc9c04;
  width: 180px;
  font-size: larger;
  margin-left: 5vw;
}

#submitNewSalary:hover {
  background-color: #fccc77;
}