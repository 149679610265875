#headerPartSelfUserEdit {
  width: 100vw;
  height: 60px;
  text-align: center;
  display: flex;
  flex-direction: column;
}

#HeaderSelfUserEdit {
  width: 100vw;
  font-size: 50;
  height: 60px;
}



#profilePictureChangeSelfUserEdit {
  width: 90vw;
  margin-left: 5vw;

}



#roomEdit_button1 {
  margin: 2px;
  background-color: #fc9c04;
  width: 180px;
  font-size: larger;
  margin-left: 5vw;
}

#roomEdit_button1:hover {
  background-color: #fccc77;
}

.formHalfWidthSelfUserEdit {
  width: 50%;
}

.fullWidthFormSelfUserEdit {
  width: 100%;
}

#submitSelfUserEditContainer {
  display: flex;
  flex-direction: row;
  text-align: center;
}


#submitSelfUserEdit1 {
  width: 20%;
  color: white;
  font-size: larger;
  padding: 1%;
  margin-left: 1%;
  margin-right: 1%;
  background: #fc9c04;
}

#submitSelfUserEdit1:hover {
  background-color: #fccc77;
}

#submitSelfUserEdit2 {
  width: 20%;
  color: white;
  font-size: larger;
  padding: 1%;
  margin-left: 1%;
  margin-right: 1%;
  background: #fc9c04;
}

#submitSelfUserEdit2:hover {
  background-color: #fccc77;
}

#submitSelfUserEdit3 {
  width: 100%;
  color: white;
  font-size: larger;
  padding: 1%;
  margin-top: 1%;
  background: #fc9c04;
}

#submitSelfUserEdit3:hover {
  background-color: #fccc77;
}

#addFormSelfUserEdit {
  margin: 5vw;
  margin-top: 0;
  width: 90vw;
}

#addSelfUserEdit {
  margin: 1%;
  padding-top: 5%;
  padding-bottom: 5%;
  width: 100%;
  background-color: #262626;
}


@media (orientation: landscape) {
  #formSelfUserEdit {
    width: 90%;
    color: white;
    font-size: 1.3vw;
  }

  #containerSelfUserEdit {
    display: flex;
    flex-direction: row;
    float: left;
    margin-left: 5vw;
  }

  #cardSelfUserEdit {
    width: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  #addFormUserEdit {
    width: 75vw;
    margin-left: 5vw;
    margin-right: 5vw;
  }
}

@media (orientation: portrait) {
  #formSelfUserEdit {
    width: 90vw;
    color: white;
    font-size: 2.5vw;
  }

  #containerSelfUserEdit {
    display: flex;
    flex-direction: column;
  }

  #cardSelfUserEdit {
    width: 200px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  #addFormUserEdit {
    width: 90vw;
    margin-left: 5vw;
  }
}


#user_reset_password {
  margin: 2px;
  background-color: #fc9c04;
  width: 180px;
  font-size: larger;
  margin-left: 5vw;
}

#user_reset_password:hover {
  background-color: #fccc77;
}


#resetPasswordUserEdit {
  margin: 5vw;
  width: 90vw;
}

#resetUserEdit {
  margin: 1%;
  padding-top: 5%;
  padding-bottom: 5%;
  width: 100%;
  background-color: #262626;
}

#formResetPasswordUser {
  color: white;
}

#submitResetPasswordUser {
  margin-top: 2%;
  margin-left: 80%;
  color: white;
  font-size: larger;
  padding: 1%;
  background: #fc9c04;
}