#headerPartInstructorShare {
  width: 100vw;
  height: 60px;
  text-align: center;
  display: flex;
  flex-direction: column;
}

#HeaderInstructorShare {
  width: 100vw;
  font-size: 50;
  height: 60px;
}

#tableInstructorShare {
  width: 90vw;
  margin: auto;
}

#track_button1 {
  margin: 2px;
  background-color: #fc9c04;
  width: 180px;
  font-size: larger;
  margin-left: 5vw;
}

#track_button1:hover {
  background-color: #fccc77;
}

.formHalfWidthInstructorShare {
  width: 50%;
}

.fullWidthFormInstructorShare {
  width: 100%;
}


#submitInstructorShare {
  margin-top: 2%;
  margin-left: 80%;
  color: white;
  font-size: larger;
  padding: 1%;
  background: #fc9c04;
}

#addFormInstructorShare {
  margin: 5vw;
  width: 90vw;
}

#addInstructorShare {
  margin: 1%;
  padding-top: 5%;
  padding-bottom: 5%;
  width: 100%;
  background-color: #262626;
}

#formInstructorShare {
  color: white;
}

@media (orientation: landscape) {
  #formInstructorShare {
    width: 90%;
    color: white;
    font-size: 1.3vw;
  }
}

@media (orientation: portrait) {
  #formInstructorShare {
    width: 90%;
    color: white;
    font-size: 2.5vw;
  }
}