#headerPartSubscriptionEdit {
  width: 90vw;
  margin-left: 5vw;
  height: 60px;
  text-align: center;
  display: flex;
  flex-direction: column;
}

#HeaderSubscriptionEdit {
  width: 90vw;
  font-size: 10;
  height: 60px;
}

#tableSubscriptionEdit {
  width: 90vw;
  margin-left: 5vw;
}

#edit_subscription {
  width: 90vw;
  margin-left: 5vw;
}

#changeReleaseAmount{
  width: 90vw;
  margin-left: 5vw;
}

#subscriptionEdit_button1 {
  margin: 2px;
  background-color: #fc9c04;
  width: 180px;
  font-size: larger;
  margin-left: 5vw;
}

#subscriptionEdit_button1:hover {
  background-color: #fccc77;
}

.formHalfWidthSubscriptionEdit {
  width: 50%;
}

.fullWidthFormSubscriptionEdit {
  width: 100%;
}


#submitSubscriptionEdit1 {
  margin-top: 2%;
  margin-left: 80%;
  color: white;
  font-size: larger;
  padding: 1%;
  background: #fc9c04;
}

#submitSubscriptionEdit1:hover {
  background-color: #fccc77;
}

#submitSubscriptionEdit2 {
  margin-top: 2%;
  margin-left: 80%;
  color: white;
  font-size: larger;
  padding: 1%;
  background: #fc9c04;
}

#submitSubscriptionEdit2:hover {
  background-color: #fccc77;
}

#submitSubscriptionEdit3 {
  margin-top: 2%;
  margin-left: 80%;
  color: white;
  font-size: larger;
  padding: 1%;
  background: #fc9c04;
}

#submitSubscriptionEdit3:hover {
  background-color: #fccc77;
}

#submitSubStartDateEdit {
  margin-top: 2%;
  margin-left: 80%;
  color: white;
  font-size: larger;
  padding: 1%;
  background: #fc9c04;
}

#submitSubStartDateEdit:hover {
  background-color: #fccc77;
}

#submitSubExpiryDateEdit {
  margin-top: 2%;
  margin-left: 80%;
  color: white;
  font-size: larger;
  padding: 1%;
  background: #fc9c04;
}

#submitSubExpiryDateEdit:hover {
  background-color: #fccc77;
}

#addFormSubscriptionEdit {
  margin: 5vw;
  width: 90vw;
}

#changeFormStartDate {
  margin: 5vw;
  width: 90vw;
}

#changeFormExpiryDate {
  margin: 5vw;
  width: 90vw;
}

#addSubscriptionEdit {
  margin: 1%;
  padding-top: 5%;
  padding-bottom: 5%;
  width: 100%;
  background-color: #262626;
}

#changeSubStartDate {
  margin: 1%;
  padding-top: 5%;
  padding-bottom: 5%;
  width: 100%;
  background-color: #262626;
}

#changeSubExpiryDate {
  margin: 1%;
  padding-top: 5%;
  padding-bottom: 5%;
  width: 100%;
  background-color: #262626;
}

#formSubscriptionEdit {
  color: white;
}

#changeformSubStartDate {
  color: white;
}

#changeformSubExpiryDate {
  color: white;
}

@media (orientation: landscape) {
  #formSubscriptionEdit {
    width: 90%;
    color: white;
    font-size: 1.3vw;
  }

  #changeformSubStartDate {
    width: 90%;
    color: white;
    font-size: 1.3vw;
  }

  #changeformSubExpiryDate {
    width: 90%;
    color: white;
    font-size: 1.3vw;
  }

  #edit_buttons {
    display: flex;
    width: 500px;
    align-items: flex-start;
    margin: auto;
    height: auto;
    flex-wrap: wrap;
  }

  .editButtonsItem {
    width: 100px;
  }

  #view_details {
    width: 15vw;
    margin: 1vw;
    background-color: #fc9c04;
    font-size: 1vw;
    margin-left: 5vw;
  }

  #view_details:hover {
    background-color: #fccc77;
  }

  #pay_rest {
    width: 15vw;
    margin: 1vw;
    background-color: #fc9c04;
    font-size: 1vw;
    margin-left: 5vw;
  }

  #pay_rest:hover {
    background-color: #fccc77;
  }

  #refund {
    width: 15vw;
    margin: 1vw;
    background-color: #fc9c04;
    font-size: 1vw;
    margin-left: 5vw;
  }

  #refund:hover {
    background-color: #fccc77;
  }

  #walk_in {
    width: 15vw;
    margin: 1vw;
    background-color: #fc9c04;
    font-size: 1vw;
    margin-left: 5vw;
  }

  #walk_in:hover {
    background-color: #fccc77;
  }

  #change_start_date {
    width: 15vw;
    margin: 1vw;
    background-color: #fc9c04;
    font-size: 1vw;
    margin-left: 5vw;
  }

  #change_start_date:hover {
    background-color: #fccc77;
  }

  #change_expiry_date {
    width: 15vw;
    margin: 1vw;
    background-color: #fc9c04;
    font-size: 1vw;
    margin-left: 5vw;
  }

  #change_expiry_date:hover {
    background-color: #fccc77;
  }

  #release_amount {
    width: 15vw;
    margin: 1vw;
    background-color: #fc9c04;
    font-size: .96vw;
    margin-left: 5vw;
  }

  #release_amount:hover {
    background-color: #fccc77;
  }
}

@media (orientation: portrait) {
  #formSubscriptionEdit {
    width: 90%;
    color: white;
    font-size: 2.5vw;
  }

  #changeformSubStartDate {
    width: 90%;
    color: white;
    font-size: 2.5vw;
  }

  #changeformSubExpiryDate {
    width: 90%;
    color: white;
    font-size: 2.5vw;
  }

  #edit_buttons {
    display: flex;
    flex-direction: column;
    width: 40vw;
    margin-left: 25vw;
    justify-content: center;
    text-align: center;
    justify-self: center;
  }

  .editButtonsItem {
    width: 40vw;
    margin: auto;
  }

  #view_details {
    width: 40vw;
    margin: 1vw;
    background-color: #fc9c04;
    font-size: 2.5vw;
    margin-left: 5vw;
  }

  #view_details:hover {
    background-color: #fccc77;
  }

  #pay_rest {
    width: 40vw;
    margin: 1vw;
    background-color: #fc9c04;
    font-size: 2.5vw;
    margin-left: 5vw;
  }

  #pay_rest:hover {
    background-color: #fccc77;
  }

  #refund {
    width: 40vw;
    margin: 1vw;
    background-color: #fc9c04;
    font-size: 2.5vw;
    margin-left: 5vw;
  }

  #refund:hover {
    background-color: #fccc77;
  }

  #walk_in {
    width: 40vw;
    margin: 1vw;
    background-color: #fc9c04;
    font-size: 2.5vw;
    margin-left: 5vw;
  }

  #walk_in:hover {
    background-color: #fccc77;
  }

  #change_start_date {
    width: 40vw;
    margin: 1vw;
    background-color: #fc9c04;
    font-size: 2.5vw;
    margin-left: 5vw;
  }

  #change_start_date:hover {
    background-color: #fccc77;
  }

  #change_expiry_date {
    width: 40vw;
    margin: 1vw;
    background-color: #fc9c04;
    font-size: 2.5vw;
    margin-left: 5vw;
  }

  #change_expiry_date:hover {
    background-color: #fccc77;
  }

  #release_amount {
    width: 40vw;
    margin: 1vw;
    background-color: #fc9c04;
    font-size: 2.5vw;
    margin-left: 5vw;
  }

  #release_amount:hover {
    background-color: #fccc77;
  }
}

#subs_button {
  margin: 2px;
  background-color: #fc9c04;
  width: 280px;
  font-size: larger;
  margin-left: 5vw;
}

#subs_button:hover {
  background-color: #fccc77;
}