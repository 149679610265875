#headerPartDetailedSubscription {
  width: 100vw;
  height: 60px;
  text-align: center;
  display: flex;
  flex-direction: column;
}

#HeaderDetailedSubscription {
  width: 100vw;
  font-size: 50;
  height: 60px;
}

#tableDetailedSubscription {
  width: 90vw;
  margin: auto;
}

#subscriptionEdit_button1 {
  margin: 2px;
  background-color: #fc9c04;
  width: 180px;
  font-size: larger;
  margin-left: 5vw;
}

#subscriptionEdit_button1:hover {
  background-color: #fccc77;
}

.formHalfWidthDetailedSubscription {
  width: 50%;
}

.fullWidthFormDetailedSubscription {
  width: 100%;
}


#submitDetailedSubscription {
  margin-top: 2%;
  margin-left: 80%;
  color: white;
  font-size: larger;
  padding: 1%;
  background: #fc9c04;
}

#submitDetailedSubscription:hover {
  background-color: #fccc77;
}

#addFormDetailedSubscription {
  margin: 5vw;
  width: 90vw;
}

#addDetailedSubscription {
  margin: 1%;
  padding-top: 5%;
  padding-bottom: 5%;
  width: 100%;
  background-color: #262626;
}

#formDetailedSubscription {
  color: white;
}

@media (orientation: landscape) {
  #formDetailedSubscription {
    width: 90%;
    color: white;
    font-size: 1.3vw;
  }

  #edit_buttons {
    display: flex;
    flex-direction: row;
    width: 80vw;
    margin-left: 5vw;
  }

  .editButtonsItem {
    width: 20vw;
  }

  #view_details {
    width: 15vw;
    margin: 1vw;
    background-color: #fc9c04;
    font-size: 1vw;
    margin-left: 5vw;
  }

  #view_details:hover {
    background-color: #fccc77;
  }

  #pay_rest {
    width: 15vw;
    margin: 1vw;
    background-color: #fc9c04;
    font-size: 1vw;
    margin-left: 5vw;
  }

  #pay_rest:hover {
    background-color: #fccc77;
  }

  #refund {
    width: 15vw;
    margin: 1vw;
    background-color: #fc9c04;
    font-size: 1vw;
    margin-left: 5vw;
  }

  #refund:hover {
    background-color: #fccc77;
  }

  #walk_in {
    width: 15vw;
    margin: 1vw;
    background-color: #fc9c04;
    font-size: 1vw;
    margin-left: 5vw;
  }

  #walk_in:hover {
    background-color: #fccc77;
  }
}

@media (orientation: portrait) {
  #formDetailedSubscription {
    width: 90%;
    color: white;
    font-size: 2.5vw;
  }

  #edit_buttons {
    display: flex;
    flex-direction: column;
    width: 40vw;
    margin-left: 25vw;
    justify-content: center;
    text-align: center;
    justify-self: center;
  }

  .editButtonsItem {
    width: 40vw;
    margin: auto;
  }

  #view_details {
    width: 40vw;
    margin: 1vw;
    background-color: #fc9c04;
    font-size: 2.5vw;
    margin-left: 5vw;
  }

  #view_details:hover {
    background-color: #fccc77;
  }

  #pay_rest {
    width: 40vw;
    margin: 1vw;
    background-color: #fc9c04;
    font-size: 2.5vw;
    margin-left: 5vw;
  }

  #pay_rest:hover {
    background-color: #fccc77;
  }

  #refund {
    width: 40vw;
    margin: 1vw;
    background-color: #fc9c04;
    font-size: 2.5vw;
    margin-left: 5vw;
  }

  #refund:hover {
    background-color: #fccc77;
  }

  #walk_in {
    width: 40vw;
    margin: 1vw;
    background-color: #fc9c04;
    font-size: 2.5vw;
    margin-left: 5vw;
  }

  #walk_in:hover {
    background-color: #fccc77;
  }
}

#subs_button {
  margin: 2px;
  background-color: #fc9c04;
  width: 280px;
  font-size: larger;
  margin-left: 5vw;
}

#subs_button:hover {
  background-color: #fccc77;
}